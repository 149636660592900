import { createPageVisibility } from "@solid-primitives/page-visibility";
import {
	Match,
	Switch,
	createEffect,
	createMemo,
	createSignal,
	onCleanup,
	onMount,
} from "solid-js";
import { CARDS_STATE, UI, useMode } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import dayjs from "~/utils/dayjs";

import { c, stylex } from "~/utils/styles";
import { BackSection } from "./BackSection";
import { CardsHome } from "./CardsHome";
import { ChessboardFooter } from "./ChessboardFooter";
import { SettingsButtons } from "./Settings";
import { NavBreadcrumbs, SidebarLayout } from "./SidebarLayout";
import { SidebarStack } from "./SidebarStack";
import { ChessboardView, PiecesSvgSheet } from "./chessboard/Chessboard";

export const ChesscardsRoot = () => {
	const mode = useMode();

	const overviewChessboardInterface = () => CARDS_STATE().chessboardOverview;
	const buildingChessboardInterface = () => CARDS_STATE().chessboardBuild;
	const reviewChessboardInterface = () => CARDS_STATE().reviewState.chessboard;
	const currentView = createMemo(() => UI().currentView());

	const cardsLoading = () => CARDS_STATE().cards === undefined;

	const sidebarContent = (
		<>
			<div
				id="sidebar-inner"
				style={stylex(c.relative, c.zIndex(100))}
				class={clsx("md:pt-[12px]")}
			>
				<Switch fallback={<CardsHome />}>

					<Match when={currentView()}>
						<SidebarStack stack={UI().sidebarStack}></SidebarStack>
					</Match>
				</Switch>
			</div>
		</>
	);
	const visibility = createPageVisibility();
	const [lastVisible, setLastVisible] = createSignal(dayjs());
	createEffect((previousVisibility) => {
		if (visibility() && previousVisibility === false) {
			if (dayjs.duration(dayjs().diff(lastVisible())).hours() >= 1) {
				// refresh page
				window.location.reload();
			}
		}
		if (visibility()) {
			setLastVisible(dayjs());
		}
		return visibility();
	});
	// const sidebarChessboardInterface = createMemo(() => last(CHESSBOARD_STACK()));
	const visibleChessboardSource = () => {
		if (mode() === "build") {
			return "build";
		}
		if (mode() === "review") {
			return "review";
		}
		return "overview";
	};

	const visibleChessboard = createMemo(() => {
		if (visibleChessboardSource() === "build") {
			return buildingChessboardInterface();
		}
		if (visibleChessboardSource() === "review") {
			return reviewChessboardInterface();
		}
		return overviewChessboardInterface();
	});
	onMount(() => {
		console.info("Cards home mounted");
	});
	onCleanup(() => {
		console.info("Cards home cleaned up");
	});

	// onMount(() => {
	// 	if (!REPERTOIRE_STATE().onboarding.isOnboarding && !repertoireLoading) {
	// 		setTimeout(() => {
	// 			if (isNative) {
	// 				Notifications.registerNotifications();
	// 			}
	// 		}, 500);
	// 	}
	// });

	return (
		<>
			<PiecesSvgSheet />
			<SidebarLayout
				loading={cardsLoading()}
				breadcrumbs={<NavBreadcrumbs />}
				sidebarContent={sidebarContent}
				settings={<SettingsButtons />}
				chessboardView={
					<>
						<ChessboardView
							class={clsx(visibleChessboardSource() !== "overview" && "hidden")}
							chessboardInterface={overviewChessboardInterface()}
						/>
						<ChessboardView
							class={clsx(visibleChessboardSource() !== "review" && "hidden")}
							chessboardInterface={reviewChessboardInterface()}
						/>
						<ChessboardView
							class={clsx(visibleChessboardSource() !== "build" && "hidden")}
							chessboardInterface={buildingChessboardInterface()}
						/>
					</>
				}
				backSection={<BackSection chessboard={visibleChessboard()} />}
				belowChessboard={<ChessboardFooter chessboard={visibleChessboard()} />}
			/>
		</>
	);
};
