import { endOfDay } from "date-fns";
import { filter, isEmpty, isNil, mapValues } from "lodash-es";
import { type Accessor, For, Show, createMemo, onCleanup, onMount } from "solid-js";
import { Spacer } from "~/components/Space";
import { ChessGame } from "~/types/ChessGame";
import type { LichessMistake } from "~/types/GameMistake";
import { type OpeningReport, OpeningsReport } from "~/types/OpeningsReport";
import {
	APP_STATE,
	BROWSING_STATE,
	MODEL_GAMES_STATE,
	OPENINGS_REPORT_STATE,
	REPERTOIRE_STATE,
	UI,
	USER_STATE,
	quick,
} from "~/utils/app_state";
import type { RepertoireProgressState } from "~/utils/browsing_state";
import { START_EPD } from "~/utils/chess";
import { clsx } from "~/utils/classes";
import { isDevelopment } from "~/utils/env";
import { pluralize } from "~/utils/pluralize";
import type { StartMistakesAnimation } from "~/utils/repertoire_state";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { AddRepertoire } from "./AddRepertoire";
import { CMText } from "./CMText";
import { ConnectAccountsSetting } from "./ConnectedAccounts";
import { createMoveReviewAnimation } from "./CorrectGameMovesAnimation";
import { FeedbackView } from "./FeedbackView";
import { LoadingSpinner } from "./LoadingSpinner";
import { MistakesStatus } from "./MistakesStatus";
import { MobileAppPromotion } from "./MobileAppPromotion";
import { ModelGameHistory } from "./ModelGameHistory";
import { OpeningsReportView } from "./OpeningsReportView";
import { PreReview } from "./PreReview";
import { ProgressCircle } from "./ProgressCircle";
import { RepertoireOverview } from "./RepertoirtOverview";
import { ReviewText } from "./ReviewText";
import { SideDot } from "./SideDot";
import {
	type SidebarAction,
	SidebarActions,
	SidebarFullWidthButton,
	SidebarSectionHeader,
} from "./SidebarActions";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTemplate } from "./SidebarTemplate";
import { TemplateSidebarView } from "./TemplateSidebarView";
import { TextAndIcon } from "./TextAndIcon";
import { initTooltip } from "./Tooltip";

export const RepertoireHome = () => {
	const mistakesAnimation = createMoveReviewAnimation();
	const userState = () => APP_STATE().userState;
	const allRepertoiresEmpty = () => APP_STATE().repertoireState.getIsRepertoireEmpty();
	const currentStreak = createMemo(() => USER_STATE().getCurrentStreak());
	const lichessMistakes = () => {
		if (isDevelopment) {
			// return [];
		}
		return APP_STATE().repertoireState.lichessMistakes;
	};
	const loadingMistakes = () => isNil(lichessMistakes());
	const loadingOpeningsReport = () => isNil(OPENINGS_REPORT_STATE().getCurrentOpeningsReport());
	const numInsights = () => {
		let report = OPENINGS_REPORT_STATE().getCurrentOpeningsReport();
		if (!report) {
			return 0;
		}
		return filter(
			report.flatMap((or) => OpeningsReport.getAllReports(or)),
			(o: OpeningReport) => !o.reviewed,
		).length;
	};
	const numMyMoves = () =>
		mapValues(
			REPERTOIRE_STATE().repertoires,
			(repertoire) => REPERTOIRE_STATE().numMyMoves?.[repertoire.id] ?? 0,
		);
	const totalDue = () => REPERTOIRE_STATE().totalMovesDue ?? 0;
	onMount(() => {
		quick((s) => {
			const runAnimation: StartMistakesAnimation = ({
				initialDue,
				finalDue,
				numCorrect,
				finalEarliestDue,
			}) => {
				mistakesAnimation.startAnimation({
					initialDue,
					finalDue,
					numCorrect,
					finalEarliestDue,
				});
			};
			s.repertoireState.onCorrectGameMovesCallbacks.add(runAnimation);
			onCleanup(() => {
				s.repertoireState.onCorrectGameMovesCallbacks.remove(runAnimation);
			});
		});
	});
	const responsive = useResponsiveV2();
	const progressStates = () =>
		mapValues(
			REPERTOIRE_STATE().repertoires,
			(repertoire) => BROWSING_STATE().repertoireProgressState[repertoire.id],
		);
	const overallEarliest = createMemo(() => {
		return REPERTOIRE_STATE().getEarliestDue();
	});
	const isQuizzing = () => {
		return !isEmpty(APP_STATE().repertoireState.reviewState.activeQueue);
	};
	const recommendedReviewSize = USER_STATE().getFrontendSetting("recommendedMovesSize")
		.value as number;
	const overallActions: Accessor<SidebarAction[]> = () => {
		const actions: SidebarAction[] = [];

		actions.push({
			text: "Practice moves",
			right: mistakesAnimation.toReviewAnimation() ?? (
				<ReviewText date={overallEarliest()} numDue={totalDue()} />
			),
			style: "primary",
			disabled: allRepertoiresEmpty(),
			onPress: () => {
				trackEvent("home.practice_all_due");
				quick((s) => {
					if (totalDue() <= recommendedReviewSize && !isQuizzing() && totalDue() > 0) {
						s.repertoireState.reviewState.startReview({
							repertoireId: undefined,
							filter: "recommended",
						});
						return;
					}
					UI().pushView(PreReview, {
						props: { repertoireId: null },
					});
					return;
				});
				return;
			},
		} as SidebarAction);
		return actions;
	};
	const gameReviewed = () => MODEL_GAMES_STATE().dailyGame?.reviewed;
	return (
		<Show when={userState().user}>
			<MobileAppPromotion />
			<SidebarTemplate header={null} actions={[]} bodyPadding={false}>
				<SidebarSectionHeader
					text="Repertoires"
					right={
						<div
							class="text-xs text-tertiary hover:text-primary transition-colors cursor-pointer opacity-70 hover:opacity-100"
							use:onClick={() => {
								trackEvent("home.add_new_repertoire");
								quick((_s) => {
									UI().pushView(AddRepertoire);
								});
							}}
						>
							<TextAndIcon text={<p>Add new</p>} icon="fa-solid fa-plus" />
						</div>
					}
				/>
				<div style={stylex(c.column, c.fullWidth, c.gap("10px"))}>
					<For each={REPERTOIRE_STATE().repertoiresList}>
						{(repertoire) => {
							const completionProgress: RepertoireProgressState | undefined =
								progressStates()[repertoire.id];
							const mastery = REPERTOIRE_STATE().masteryFromEpd[repertoire.id][START_EPD];
							const getProgress: () => {
								completed: boolean;
								percentComplete: number;
								type: "mastery" | "completion";
							} = () => {
								if (completionProgress?.completed) {
									return {
										completed: mastery === 1,
										percentComplete: mastery,
										type: "mastery",
									};
								}
								if (completionProgress?.percentComplete === 1) {
									return {
										completed: true,
										percentComplete: 1,
										type: "completion",
									};
								}
								return {
									completed: false,
									percentComplete: completionProgress?.percentComplete ?? 0,
									type: "completion",
								};
							};
							const { completed, percentComplete, type } = getProgress();
							return (
								<SidebarFullWidthButton
									action={{
										style: "wide",
										shrink: "left",
										text: (
											<div class={clsx("flex items-center gap-3 row whitespace-wrap")}>
												{REPERTOIRE_STATE().hasMultipleRepertoires() && (
													<SideDot side={repertoire.side} />
												)}
												<p class={clsx("text-base", "leading-4")}>{repertoire.name}</p>
											</div>
										),
										right: (
											<div class="whitespace-nowrap">
												<TextAndIcon
													text={
														<div>
															<p
																class={clsx(
																	percentComplete === 0 || percentComplete === 1
																		? "text-tertiary"
																		: "text-secondary",
																	"font-semibold text-xs tabular-nums",
																)}
															>
																{numMyMoves()[repertoire.id] > 0
																	? `${Math.round(percentComplete * 100)}% ${type === "completion" ? "complete" : "mastered"}`
																	: "Not started"}
															</p>
														</div>
													}
													icon={
														completed ? (
															<i class="fa-solid fa-circle-check text-xs"></i>
														) : (
															<div class={clsx("square-[12px]")}>
																<ProgressCircle
																	progress={percentComplete}
																	color={type === "completion" ? c.green[45] : c.yellow[45]}
																/>
															</div>
														)
													}
												/>
											</div>
										),
										onPress: () => {
											quick((_s) => {
												trackEvent("home.select_side", {
													side: repertoire.side,
												});
												animateSidebar("right");
												BROWSING_STATE().setActiveRepertoire(repertoire.id);
												UI().pushView(RepertoireOverview);
											});
										},
									}}
								/>
							);
						}}
					</For>
				</div>

				<Spacer between={["table", "table-header"]} />
				<Show when={!allRepertoiresEmpty()}>
					<SidebarSectionHeader
						text="Daily tasks"
						right={
							<p
								class={clsx(
									"font-semibold items-center flex text-xs select-none",
									currentStreak().renewedToday || currentStreak().currentStreak === 0
										? "text-tertiary opacity-70"
										: "text-secondary",
								)}
								ref={(x) => {
									initTooltip({
										ref: x,
										content: () => (
											<p>
												{currentStreak().gracePeriod
													? `You have a ${currentStreak().currentStreak} day streak, but you didn't review yesterday, your streak will reset at the end of the day!`
													: currentStreak().currentStreak > 0
														? `You've reviewed your daily tasks ${pluralize(currentStreak().currentStreak, "day")} in a row!`
														: `You don't have a streak currently, review some moves to get started!`}
											</p>
										),
										maxWidth: 200,
									});
								}}
							>
								{currentStreak().currentStreak > 0
									? !currentStreak().renewedToday
										? `Extend your ${currentStreak().currentStreak} day streak`
										: `${currentStreak().currentStreak} day streak`
									: "No streak"}
								<i
									class={clsx(
										"fas pl-2 ",
										!currentStreak().renewedToday || currentStreak().currentStreak === 0
											? "fa-fire text-sm"
											: "fa-circle-check text-xs",
										currentStreak().renewedToday || currentStreak().currentStreak === 0
											? " text-tartiary"
											: "text-orange-50",
									)}
								/>
							</p>
						}
					/>
					<div style={stylex(c.column, c.fullWidth, c.gap("10px"))}>
						<Show when={!isEmpty(overallActions())}>
							<For each={overallActions()}>
								{(action) => <SidebarFullWidthButton action={action} />}
							</For>
						</Show>
						<Show when={MODEL_GAMES_STATE().dailyGame && USER_STATE().flagEnabled("model_games")}>
							<SidebarFullWidthButton
								action={{
									style: "primary",
									text: responsive().isMobile ? "View model game" : "Play through a model game",
									right: gameReviewed() ? (
										<ReviewText
											numDue={0}
											descriptor="New game"
											date={endOfDay(new Date()).toISOString()}
										/>
									) : (
										<TextAndIcon
											text={
												<p
													class={clsx(
														"text-secondary text-xs font-semibold",
														"truncate",
														gameReviewed() ? "text-tertiary" : "text-secondary",
													)}
												>
													{MODEL_GAMES_STATE().dailyGame &&
														(MODEL_GAMES_STATE().dailyGame!.source === "Lichess"
															? ChessGame.getEcoCodeName(MODEL_GAMES_STATE().dailyGame!)
															: ChessGame.formatChessGameTitle(
																	MODEL_GAMES_STATE().dailyGame!,
																	responsive().isMobile,
																))}
												</p>
											}
											icon={
												MODEL_GAMES_STATE().dailyGame?.reviewed ? (
													<i class="fa-solid fa-circle-check text-xs"></i>
												) : (
													"fa-solid text-blue-60 fa-circle-arrow-right text-xs"
												)
											}
										/>
									),
									onPress: () => {
										quick((s) => {
											if (MODEL_GAMES_STATE().dailyGame?.reviewed) {
												trackEvent("home.view_model_game_history");
												UI().pushView(ModelGameHistory);
											} else {
												trackEvent("home.view_model_game");
												s.repertoireState.modelGamesState.reviewGame(
													MODEL_GAMES_STATE().dailyGame!,
												);
											}
										});
									},
								}}
							/>
						</Show>
					</div>
					<Spacer between={["table", "table-header"]} />
					<SidebarSectionHeader text="Review your online games" />
					<div style={stylex(c.column, c.fullWidth, c.gap("10px"))}>
						<Show when={userState().isConnectedToExternal()}>
							<SidebarFullWidthButton
								action={{
									style: "primary",
									text: "Correct your opening mistakes",
									disabled: loadingMistakes(),
									right:
										mistakesAnimation.mistakesAnimation?.() ??
										(loadingMistakes() ? (
											<LoadingSpinner class="text-[12px]" />
										) : (
											<MistakesStatus />
										)),
									onPress: () => {
										quick((s) => {
											trackEvent("home.review_mistakes");
											if (isEmpty(lichessMistakes())) {
												UI().pushView(TemplateSidebarView, {
													props: {
														text: "We didn't find any mistakes in your recent games.",
														header: "No mistakes found",
														actions: [
															{
																text: "Manage your connected accounts",
																onPress: () => {
																	quick((_s) => {
																		UI().pushView(ConnectAccountsSetting);
																	});
																},
																style: "secondary",
															},
														],
													},
												});
											} else {
												s.repertoireState.reviewState.startReview({
													lichessMistakes: lichessMistakes() as LichessMistake[],
												});
											}
										});
									},
								}}
							/>
							<SidebarFullWidthButton
								action={{
									text: "View performance by opening",
									style: "primary",
									disabled: loadingOpeningsReport(),
									right: OPENINGS_REPORT_STATE().openingsReportsByTimeRange ? (
										<TextAndIcon
											text={
												<div class="tabular-nums">
													<p
														class={clsx(
															numInsights() === 0 ? "text-tertiary" : "text-secondary",
															"font-semibold text-xs truncate",
														)}
													>
														{numInsights() > 0
															? pluralize(numInsights(), "insight")
															: "No new insights"}
													</p>
												</div>
											}
											icon={
												<i
													class={clsx(
														"fa-solid fa-circle-info text-xs",
														numInsights() === 0 ? "text-tertiary" : "text-purple-60",
													)}
												></i>
											}
										/>
									) : (
										<LoadingSpinner class="text-[12px]" />
									),
									onPress: () => {
										quick((_s) => {
											UI().pushView(OpeningsReportView, {
												props: {
													reports: OPENINGS_REPORT_STATE().openingsReportsByTimeRange!,
												},
											});
										});
									},
								}}
							/>
						</Show>
						<Show when={!userState().isConnectedToExternal()}>
							<div style={stylex(c.column, c.fullWidth, c.gap("10px"))}>
								<SidebarFullWidthButton
									action={{
										style: "primary",
										text: "Correct your opening mistakes",
										right: (
											<CMText class="text-secondary text-xs font-semibold">
												{responsive().isMobile ? "Connect" : "Connect account"}…
											</CMText>
										),
										onPress: () => {
											trackEvent("home.connect_account");
											quick((_s) => {
												UI().pushView(ConnectAccountsSetting);
											});
										},
									}}
								/>
							</div>
						</Show>
					</div>
					<Spacer between={["table", "table-header"]} />
				</Show>
				<SidebarSectionHeader text="Contact us" />
				<SidebarActions
					actions={[
						{
							onPress: () => {
								trackEvent("home.contact.discord");
								window.open("https://discord.gg/vNzfu5VetQ", "_blank");
							},
							text: "Join the Chessbook Discord",
							style: "secondary",
						} as SidebarAction,
						// {
						// 	onPress: () => {
						// 		quick((s) => {
						// 			trackEvent("home.contact.twitter");
						// 			window.open("https://twitter.com/chessbookcom", "_blank");
						// 		});
						// 	},
						// 	text: "Follow us on Twitter",
						// 	style: "secondary",
						// } as SidebarAction,
						{
							onPress: () => {
								quick((_s) => {
									trackEvent("home.contact.feedback");
									UI().pushView(FeedbackView);
								});
							},
							text: "Share your feedback",
							style: "secondary",
						} as SidebarAction,
						{
							onPress: () => {
								quick((_s) => {
									trackEvent("home.contact.get_sponsored");
									UI().pushView(TemplateSidebarView, {
										props: {
											text: (
												<>
													We're looking for people to help get the word out about Chessbook!
													<Spacer between={["body-text", "body-text"]} />
													If you make chess content and enjoy using this site, please reach out to
													us! We'd love to sponsor you. You can email us at{" "}
													<a href="mailto:marketing@chessbook.com" class="text-primary">
														marcus@chessbook.com
													</a>
													<Spacer between={["body-text", "body-text"]} />
													If you want to earn money from referring people to Chessbook, sign up as
													an affiliate! You'll earn money from every referral that signs up for
													Chessbook. Just go to the{" "}
													<a href="https://chessbook.tolt.io/" class="text-primary">
														chessbook.tolt.io
													</a>
												</>
											),
											header: "Help us spread the word!",
											actions: [],
										},
									});
								});
							},
							text: "Help us spread the word",
							style: "secondary",
						} as SidebarAction,
					]}
				/>
			</SidebarTemplate>
		</Show>
	);
};
