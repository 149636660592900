export type UserFlag =
	| "quiz_plans"
	| "multiple_repertoires"
	| "app_promotion_dismissed"
	| "pausing_moves"
	| "course_creation"
	| "courses"
	| "model_games"
	| "move_exclusion"
	| "amateur_games"
	| "characteristics";

export const DEVELOPMENT_FLAGS: UserFlag[] = [
	"course_creation",
	"courses",
	"characteristics",
	"move_exclusion",
]; //  ["course_creation", "courses"];
export const ADMIN_FLAGS: UserFlag[] = ["course_creation", "courses", "amateur_games"];
export const STAGING_FLAGS: UserFlag[] = ["course_creation", "courses"];
export const BETA_FLAGS: UserFlag[] = [];
export const GENERAL_FLAGS: UserFlag[] = [
	"multiple_repertoires",
	"model_games",
	"courses",
	"characteristics",
	"move_exclusion",
	"amateur_games",
];

export const BETA_FEATURES = [
	{
		flag: "quiz_plans" as UserFlag,
		name: "Quiz plans",
		description: "Practice the middlegame plans in your repertoire, during review",
	},
];
