// @ts-ignore
import Base64 from "crypto-js/enc-base64";
// @ts-ignore
import sha256 from "crypto-js/sha256";

import { AppLauncher } from "@capacitor/app-launcher";
import { Preferences } from "@capacitor/preferences";
import { uuid4 } from "@sentry/utils";
import cryptoRandomString from "crypto-random-string";
import dayjs from "dayjs";
import { filter, noop } from "lodash-es";
import posthog from "posthog-js";
import { DEFAULT_LICHESS_SCOPES } from "~/constants";
import { THRESHOLD_OPTIONS } from "~/threshold_options";
import type { Side } from "~/types/Side";
import type { User } from "~/types/User";
import type { Uuid } from "~/types/Uuid";
import type { AuthResponse } from "~/types/dtos";
import client from "~/utils/client";
import { trackEvent } from "~/utils/trackEvent";
import type { AppState } from "./app_state";
import { JWT_COOKIE_KEY, TEMP_USER_UUID } from "./cookies";
import { isDevelopment, isNative, isStaging } from "./env";
import {
	ADMIN_FLAGS,
	BETA_FEATURES,
	DEVELOPMENT_FLAGS,
	GENERAL_FLAGS,
	STAGING_FLAGS,
	type UserFlag,
} from "./features";
import { type FrontendSettingOption, type FrontendSettings, SETTINGS } from "./frontend_settings";
import { LICHESS_CLIENT_ID, LICHESS_REDIRECT_URI } from "./oauth";
import { createQuick } from "./quick";
import { DEFAULT_ELO_RANGE } from "./repertoire_state";
import type { StateGetter, StateSetter } from "./state_setters_getters";
import { StorageItem } from "./storageItem";
import type { BoardThemeId, PieceSetId } from "./theming";
import { identify, setUserIdentifiedById } from "./user_properties";

export interface UserPuzzleStats {
	highScores: Record<string, number>;
	blunderPuzzlesGlicko: number;
}

export interface UserState {
	quick: (fn: (_: UserState) => void) => void;
	token: StorageItem<string | undefined>;
	tempUserUuid: StorageItem<string | undefined>;
	user?: User;
	profileModalOpen?: boolean;
	setUser: (user: User) => void;
	puzzleStats: UserPuzzleStats | null;
	handleAuthResponse: (response: AuthResponse) => void;
	setRatingSystem: (system: string) => Promise<void>;
	setRatingRange: (range: string) => Promise<void>;
	setTargetDepth: (t: number) => Promise<void>;
	getUserRatingDescription: () => string;
	getSingleElo: () => number;
	getCurrentStreak: () => {
		currentStreak: number;
		gracePeriod: boolean;
		remainingHours?: number;
		renewedToday: boolean;
	};
	getCurrentThreshold: () => number;
	authStatus: AuthStatus;
	fetchPuzzleStats: () => Promise<void>;
	updateUserRatingSettings: (
		params: Partial<{
			ratingSystem: string;
			ratingRange: string;
			missThreshold: number;
		}>,
	) => Promise<void>;
	updateUserSettings: (_: {
		theme?: BoardThemeId;
		pieceSet?: PieceSetId;
		chesscomUsername?: string | null;
		iosDeviceToken?: string;
		androidDeviceToken?: string;
		flags?: UserFlag[];
		frontendSettings?: FrontendSettings;
	}) => Promise<void>;
	isUpdatingEloRange: boolean;
	pastLandingPage?: boolean;
	isSubscribed: () => boolean;
	getCheckoutLink: (annual: boolean) => Promise<string>;
	flagEnabled: (flag: UserFlag) => boolean;
	getEnabledFlags: () => UserFlag[];
	getBetaFlagsEnabled: () => UserFlag[];
	setFlag(flag: UserFlag, enabled: boolean): void;
	authWithLichess: (_: Omit<LichessAuthState, "scopes">) => void;
	setChesscomUsername: (username: string | null) => Promise<void>;
	isConnectedToExternal: () => boolean;
	setLichessToken: (
		token: string | null,
		username: string | null,
		scopes: string[],
	) => Promise<void>;
	loadAuthData: () => Promise<void>;
	getFrontendSetting: (settingKey: keyof FrontendSettings) => FrontendSettingOption<unknown>;
	logout: () => void;
	deleteAccount: () => Promise<void>;
}

export interface LichessAuthState {
	source: "onboarding" | "import" | "setting" | "onboarding-import";
	importType?: "games" | "study";
	repertoireId?: Uuid;
	side?: Side;
	scopes: string[];
}

export enum AuthStatus {
	Authenticated = "Authenticated",
	Unauthenticated = "Unauthenticated",
	Initial = "Initial",
	Authenticating = "Authenticating",
}

type Stack = [UserState, AppState];
const selector = (s: AppState): Stack => [s.userState, s];
const DEFAULT_RATING_SYSTEM = "Lichess";

export const getInitialUserState = (
	_set: StateSetter<AppState, any>,
	_get: StateGetter<AppState, any>,
) => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _set((s) => fn(selector(s)));
	};
	const setOnly = <T,>(fn: (stack: UserState) => T, _id?: string): T => {
		return _set((s) => fn(s.userState));
	};
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _get((s) => fn(selector(s)));
	};
	const initialState = {
		tempUserUuid: new StorageItem(TEMP_USER_UUID, undefined),
		puzzleStats: null,
		token: new StorageItem(JWT_COOKIE_KEY, undefined),
		isUpdatingEloRange: false,
		handleAuthResponse: (response: AuthResponse) => {
			set(([s, appState]) => {
				const { token, user, firstAuthentication } = response;
				if (firstAuthentication) {
					trackEvent("login.first_login");
				}
				s.token.value = token;
				s.tempUserUuid.value = user?.id;
				s.setUser(user);
				s.authStatus = AuthStatus.Authenticated;
				appState.repertoireState.fetchRepertoire(false);
				appState.repertoireState.fetchLichessMistakes();
				appState.repertoireState.modelGamesState.fetchModelGameHistory();
				appState.repertoireState.modelGamesState.fetchDailyModelGame();
				appState.repertoireState.openingReportsState.fetchOpeningsReport();
			});
		},
		loadAuthData: () => {
			return set(([s]) => {
				return Promise.all([s.token.load(), s.tempUserUuid.load()])
					.then(([cookieToken, tempUserUuid]) => {
						set(([s]) => {
							if (!cookieToken) {
								s.authStatus = AuthStatus.Unauthenticated;
							}
							if (!tempUserUuid) {
								const uuid = uuid4();
								s.tempUserUuid.value = uuid;
							}
						});
					})
					.catch((error) => {
						console.error("Error fetching user auth data from local storage", error);
						return Promise.reject(error);
					});
			});
		},
		deleteAccount: () => {
			return set(([_s, _appState]) => {
				return client.post("/api/v1/user/delete_account").then(() => {
					set(([s]) => {
						s.logout();
					});
				});
			});
		},
		logout: () => {
			set(([s, appState]) => {
				posthog.reset();
				setUserIdentifiedById(null);
				s.token.value = undefined;
				s.tempUserUuid.value = uuid4();
				s.authStatus = AuthStatus.Unauthenticated;
				// fetchUser().then((user: User) => {
				// 	set(([s]) => {
				// 	s.userState.handleAuthResponse(resp.data);
				// 		s.setUser(user);
				// 		s.authStatus = AuthStatus.Authenticated;
				// 	});
				// });
				appState.repertoireState.fetchRepertoire(false);
				appState.repertoireState.fetchLichessMistakes();
			});
		},
		setUser: (user: User) => {
			set(([s, appState]) => {
				let oldUser = s.user;
				s.user = user;
				if (
					!oldUser ||
					user.missThreshold !== oldUser.missThreshold ||
					user.eloRange !== oldUser.eloRange
				) {
					appState.repertoireState.updateRepertoireStructures();
				}
			});
		},
		getBetaFlagsEnabled: () => {
			return get(([s]) => {
				const beta_flags = BETA_FEATURES.map((f) => f.flag);
				return filter(s.getEnabledFlags(), (flag) => beta_flags.includes(flag));
			});
		},
		getEnabledFlags: () => {
			return get(([s]) => {
				return [
					...new Set([
						...(s.user?.flags ?? []),
						...(isDevelopment ? DEVELOPMENT_FLAGS : []),
						...(isStaging ? STAGING_FLAGS : []),
						...(s.user?.isAdmin ? ADMIN_FLAGS : []),
						...GENERAL_FLAGS,
					]),
				];
			});
		},
		flagEnabled: (flag: UserFlag) => {
			return get(([s]) => {
				return s.getEnabledFlags().includes(flag);
			});
		},
		getCurrentStreak: () => {
			return get(([s]) => {
				// if (isDevelopment) {
				// 	return { currentStreak: 1, gracePeriod: false, renewedToday: true };
				// }
				let lastStreakDate = s.user?.lastStreakDate;
				if (!lastStreakDate) {
					return { currentStreak: 0, gracePeriod: false, renewedToday: false };
				}
				// debugger;
				let streakDate = dayjs(new Date(lastStreakDate));
				streakDate = streakDate.startOf("day");
				const compareDate = dayjs().startOf("day");
				let daysPassed = compareDate.diff(streakDate, "day");
				if (daysPassed === 2) {
					let midnight = dayjs().startOf("day").add(1, "day").startOf("day");
					let remainingHours = Math.abs(dayjs().diff(midnight, "hour"));
					return {
						currentStreak: s.user?.currentStreak ?? 0,
						gracePeriod: true,
						remainingHours,
						renewedToday: false,
					};
				}
				if (daysPassed > 2) {
					return { currentStreak: 0, gracePeriod: false, renewedToday: false };
				}
				return {
					currentStreak: s.user?.currentStreak ?? 0,
					gracePeriod: false,
					renewedToday: daysPassed <= 0,
				};
			});
		},
		getSingleElo: () => {
			return get(([s, _appState]) => {
				return s.user?.singleElo ?? 1500;
			});
		},
		getCurrentThreshold: () => {
			return get(([s, _appState]) => {
				return (s.user?.missThreshold ?? DEFAULT_THRESHOLD * 100) / 100;
			});
		},
		isSubscribed: () => {
			return get(([s]) => {
				// if (isDevelopment) {
				// 	console.debug("Mocking no subscription");
				// 	return false;
				// }
				return s.user?.subscribed ?? false;
			});
		},
		getUserRatingDescription: () => {
			return get(([s]) => {
				return `${s.user?.ratingRange || DEFAULT_ELO_RANGE.join("-")} ${
					s.user?.ratingSystem || DEFAULT_RATING_SYSTEM
				}`;
			});
		},
		getFrontendSetting: (settingKey: keyof FrontendSettings): FrontendSettingOption<string> => {
			return get(([s]) => {
				const value = s.user?.frontendSettings?.[settingKey] ?? SETTINGS[settingKey].default;
				const option = SETTINGS[settingKey].options.find((option) => option.value === value);
				if (option) {
					return option;
				}
				return SETTINGS[settingKey].options[0];
			});
		},
		updateUserSettings: (settings) =>
			set(([s]) => {
				const { theme, pieceSet } = settings;
				if (pieceSet) {
					s.user!.pieceSet = pieceSet;
				}
				if (theme) {
					s.user!.theme = theme;
				}
				return client.post("/api/v1/user/settings", settings).then(({ data }: { data: User }) => {
					set(([s]) => {
						s.setUser(data);
					});
				});
			}),
		updateUserRatingSettings: (params) =>
			set(([s]) => {
				s.isUpdatingEloRange = true;
				return client
					.post("/api/v1/user/elo_range", params)
					.then(({ data }: { data: User }) => {
						set(([s, appState]) => {
							s.setUser(data);
							appState.repertoireState.positionReports = {};
							appState.repertoireState.fetchRepertoire();
						});
					})
					.finally(() => {
						set(([s, _appState]) => {
							s.isUpdatingEloRange = false;
						});
					});
			}),
		getCheckoutLink: (annual: boolean) => {
			return get(([_s]) => {
				return client
					.post("/api/stripe/create-checkout-session", {
						annual,
						experiment: false,
						// @ts-ignore
						toltReferral: window.tolt_referral,
					})
					.then(({ data }: { data: { url: string } }) => {
						return data.url;
					})
					.finally(noop);
			});
		},
		setTargetDepth: (t: number) => {
			return set(([s]) => {
				s.user!.missThreshold = t * 100;
				trackEvent("user.update_coverage_target", {
					target: `1 in ${Math.round(1 / t)} games`,
				});
				return s.updateUserRatingSettings({ missThreshold: s.user!.missThreshold });
			});
		},
		setDeviceToken: (token: string) => {
			return set(([s]) => {
				trackEvent("user.update_device_token");
				return s.updateUserSettings({ iosDeviceToken: token });
			});
		},
		setRatingSystem: (system: string) => {
			return set(([s]) => {
				trackEvent("user.update_rating_system", { rating_system: system });
				s.user!.ratingSystem = system;
				return s.updateUserRatingSettings({ ratingSystem: system });
			});
		},
		setRatingRange: (range: string) => {
			return set(([s]) => {
				trackEvent("user.update_rating_range", { rating_range: range });
				s.user!.ratingRange = range;
				return s.updateUserRatingSettings({ ratingRange: range });
			});
		},
		isConnectedToExternal: () => {
			return get(([s]) => {
				return !!(s.user?.lichessUsername || s.user?.chesscomUsername);
			});
		},
		setChesscomUsername: (username) => {
			return set(([s]) => {
				return s.updateUserSettings({ chesscomUsername: username });
			});
		},
		setLichessToken: (token, username, scopes) => {
			return client
				.post("/api/set_lichess_token", { token, username, scopes })
				.then(({ data }: { data: User }) => {
					set(([s, appState]) => {
						s.setUser(data);
						appState.repertoireState.fetchLichessMistakes();
					});
				})
				.finally(noop);
		},
		authWithLichess: (_state) => {
			set(([_s]) => {
				const codeVerifier = cryptoRandomString({ length: 64, type: "base64" });
				const state: LichessAuthState = { ..._state, scopes: DEFAULT_LICHESS_SCOPES };
				Promise.all([
					Preferences.set({
						key: "lichess.code_verifier",
						value: codeVerifier,
					}),
					Preferences.set({ key: "lichess.state", value: JSON.stringify(state) }),
				]).then(() => {
					const params = new URLSearchParams({
						client_id: LICHESS_CLIENT_ID,
						redirect_uri: LICHESS_REDIRECT_URI,
						code_challenge: Base64.stringify(sha256(codeVerifier))
							.replace(/\+/g, "-")
							.replace(/\//g, "_")
							.replace(/=/g, ""),
						response_type: "code",
						scope: state.scopes!.join(" "),
						state: JSON.stringify(state),
						code_challenge_method: "S256",
					}).toString();
					const url = `https://lichess.org/oauth?${params}`;
					if (isNative) {
						AppLauncher.openUrl({ url });
					} else {
						window.location.href = url;
					}
				});
			});
		},
		setFlag: (flag: UserFlag, enabled: boolean) => {
			set(([s]) => {
				s.user!.flags = s.user!.flags.filter((f) => f !== flag);
				if (enabled) {
					s.user!.flags.push(flag);
				}
				return s.updateUserSettings({ flags: s.user!.flags as UserFlag[] });
			});
		},
		fetchPuzzleStats: async () => {
			const response = await client.get("/api/v1/user/puzzle_stats");
			const stats = response.data;
			set(([s]) => {
				s.puzzleStats = stats;
			});
		},
		user: undefined,
		authStatus: AuthStatus.Initial,
		...createQuick<UserState>(setOnly),
	} as UserState;

	return initialState;
};

export const getRecommendedMissThreshold = (range: string) => {
	const match = range.match(/\d+/);
	if (!match) {
		return DEFAULT_THRESHOLD;
	}
	const lowerBound = Number.parseInt(match[0], 10);

	if (lowerBound <= 1000) {
		return 1 / 75;
	}
	if (lowerBound <= 1200) {
		return 1 / 75;
	}
	if (lowerBound <= 1400) {
		return 1 / 100;
	}
	if (lowerBound <= 1600) {
		return 1 / 100;
	}
	if (lowerBound <= 1800) {
		return 1 / 150;
	}
	if (lowerBound <= 2000) {
		return 1 / 200;
	}
	if (lowerBound <= 2200) {
		return 1 / 200;
	}
	return 1 / 200;
};

export const DEFAULT_THRESHOLD = THRESHOLD_OPTIONS[0].value;

export const trackModule = (module: string) => {
	identify({ last_module: module });
};
