import { clsx } from "~/utils/classes";

export const LoadingSpinner = (props: {
	class?: string;
	ref?: HTMLDivElement | undefined;
}) => {
	return (
		<div>
			<i class={clsx("fa fa-circle-notch animate-spin block", props.class)} ref={props.ref} />
		</div>
	);
};
