import { createEffect, createSignal, onMount } from "solid-js";
import { Portal } from "solid-js/web";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "~/constants";
import { REPERTOIRE_STATE, USER_STATE, quick } from "~/utils/app_state";
import { getStatic } from "~/utils/assets";
import { clsx } from "~/utils/classes";
import { isNative } from "~/utils/env";
import { isMobile } from "~/utils/isMobile";
import { c, stylex } from "~/utils/styles";
import { trackEvent } from "~/utils/trackEvent";
import { Pressable } from "./Pressable";
import { Spacer } from "./Space";

export const APP_PROMOTION_DISMISSED = "app_promotion_dismissed";

export const MobileAppPromotion = () => {
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const [mounted, setMounted] = createSignal(false);
	onMount(() => {
		setTimeout(() => {
			setMounted(true);
		}, 1000);
	});
	const user = () => USER_STATE().user;
	const repertoireLoading = () => REPERTOIRE_STATE().repertoires === undefined;
	const hidden = () =>
		user()?.flags.includes(APP_PROMOTION_DISMISSED) ||
		onboarding().isOnboarding ||
		isMobile() ||
		isNative ||
		repertoireLoading();
	createEffect(() => {
		if (!hidden() && mounted()) {
			trackEvent("app_promotion.shown");
		}
	});

	return (
		<Portal mount={document.body}>
			<div
				class={clsx(
					"fixed  z-1000 bottom-0 right-0 transition-all transition-duration-300",
					hidden() ? "translate-x-full" : mounted() ? "translate-x-0" : "translate-x-full",
				)}
				id="mobile-app-promotion"
			>
				<div
					class={clsx(
						"h-[400px] w-[300px] bg-gray-14 rounded-xl relative p-6 pt-0 column mr-4 mb-4 transition-all transition-duration-300",
					)}
					style={stylex(
						hidden() || !mounted()
							? c.shadow(0, 0, 0, 0, c.hsl(0, 0, 0, 0))
							: c.shadow(0, 10, 60, 0, c.hsl(0, 0, 0, 50)),
					)}
				>
					<Pressable
						onPress={() => {
							quick((s) => {
								trackEvent("app_promotion.dismissed");
								if (!s.userState.user) return;
								s.userState.setFlag(APP_PROMOTION_DISMISSED, true);
							});
						}}
						class="absolute  top-2 right-2 p-2"
					>
						<i class=" fa fa-xmark text-secondary text-base" />
					</Pressable>
					<h1 class="text-lg text-primary font-semibold pt-8">Get the Chessbook app!</h1>
					<Spacer height={36} />
					<div class=" aspect-[1/2] grow h-auto self-center">
						<img src={getStatic("/app_promo/screenshot.svg")} />
					</div>
					<Spacer height={36} />
					<div class="row">
						<a
							href={APP_STORE_LINK}
							use:onClick={() => {
								trackEvent("app_promotion.clicked", { store: "app_store" });
							}}
							target="_blank"
							rel="noopener noreferrer"
							class="h-[30px] grow"
						>
							<img class=" h-full w-full" src={getStatic("/app_promo/app_store.svg")} />
						</a>
						<Spacer width={32} />
						<a
							href={PLAY_STORE_LINK}
							use:onClick={() => {
								trackEvent("app_promotion.clicked", { store: "play_store" });
							}}
							target="_blank"
							rel="noopener noreferrer"
							class="h-[30px] grow"
						>
							<img class=" grow h-full w-full" src={getStatic("/app_promo/play_store.svg")} />
						</a>
					</div>
				</div>
			</div>
		</Portal>
	);
};
