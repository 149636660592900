import { destructure } from "@solid-primitives/destructure";
import { filter, includes, isEmpty, isEqual, isNil, map, reverse, some } from "lodash-es";
import {
	type Accessor,
	For,
	type JSXElement,
	type Setter,
	Show,
	createEffect,
	createMemo,
	createSignal,
	onCleanup,
	onMount,
} from "solid-js";
import { Spacer } from "~/components/Space";
import type { RepertoireMoveDTO } from "~/rspc";
import { MoveRating } from "~/types/MoveRating";
import { MoveTag } from "~/types/MoveTag";
import type { Repertoire } from "~/types/Repertoire";
import type { Side } from "~/types/Side";
import { TableResponse } from "~/types/TableResponse";
import { Kep } from "~/types/kep";
import { renderAnnotation } from "~/utils/annotations";
import {
	APP_STATE,
	BROWSING_STATE,
	REPERTOIRE_STATE,
	REVIEW_STATE,
	UI,
	USER_STATE,
	useMode,
} from "~/utils/app_state";
import { quick } from "~/utils/app_state";
import { clsx } from "~/utils/classes";
import { isMobile } from "~/utils/isMobile";
import {
	type TableResponseRow,
	getMovesTableColumnsLeft,
	getMovesTableColumnsRight,
} from "~/utils/movesTableColumns";
import { createPrevious } from "~/utils/signals/createPrevious";
import { c, stylex } from "~/utils/styles";
import { renderThreshold } from "~/utils/threshold";
import { trackEvent } from "~/utils/trackEvent";
import { useResponsiveV2 } from "~/utils/useResponsive";
import { AnnotationEditSidebar } from "./AnnotationEditSidebar";
import { CMText } from "./CMText";
import { CourseAvatar } from "./CourseAvatar";
import { DeleteLineView } from "./DeleteLineView";
import { DisableMoveConfirmation } from "./DisableMoveConfirmation";
import { Pressable } from "./Pressable";
import { animateSidebar } from "./SidebarContainer";
import { SidebarTable, type SidebarTableColumn } from "./SidebarTable";
import { initTooltip } from "./Tooltip";
import { TransposedView } from "./TransposedView";

export const RepertoireMovesTable = (props: {
	unclickable?: boolean;
	canAnnotate?: boolean;
	hideHeaders?: boolean;
	epd?: string;
	repertoire?: Repertoire;
	activeSide: Side;
	showOtherMoves?: Accessor<boolean>;
	usePeerRates: Accessor<boolean>;
	allLocalStockfish: boolean;
	side: Side;
	responses: Accessor<TableResponse[]>;
	setLoading?: Setter<boolean>;
}) => {
	const responsive = useResponsiveV2();
	const mode = useMode();
	const [expandedLength, setExpandedLength] = createSignal(0);
	const [editingAnnotations, setEditingAnnotations] = createSignal(false);
	const [excludingMoves, setExcludingMoves] = createSignal(false);
	const onboarding = () => REPERTOIRE_STATE().onboarding;
	const activeRepertoireId = () => BROWSING_STATE().activeRepertoireId!;

	const currentLine = () => {
		if (mode() === "review") {
			return REVIEW_STATE().chessboard.get((v) => v).moveLog;
		}
		return BROWSING_STATE().chessboard.get((v) => v).moveLog;
	};
	const moveNumber = () => Math.floor(currentLine().length / 2) + 1;
	const myTurn = () => props.side === props.activeSide;
	const anyMine = () => some(props.responses(), (m) => m.repertoireMove?.mine);
	const firstWhiteMove = () =>
		moveNumber() === 1 && props.side === "white" && myTurn() && !anyMine();

	const { trimmedResponses, truncated } = destructure(() => {
		const anyNeeded = some(props.responses(), (m) => m.suggestedMove?.needed);
		const MIN_TRUNCATED = 1;
		const MAX_ONBOARDING_OTHER_SIDE = 3;
		const MAX_ONBOARDING_MY_SIDE = 8;
		const trimmedResponses = filter(props.responses(), (r, i) => {
			if (mode() === "browse") {
				return r.repertoireMove;
			}
			if (expandedLength() === 0 && onboarding().isOnboarding) {
				if (firstWhiteMove() && i < 7) {
					return true;
				}
				if (
					(myTurn() && i >= MAX_ONBOARDING_MY_SIDE) ||
					(!myTurn() && i >= MAX_ONBOARDING_OTHER_SIDE)
				) {
					return false;
				}
			}
			if (i < expandedLength()) {
				return true;
			}
			if (r.repertoireMove) {
				return true;
			}
			if (r.stockfishMove) {
				return true;
			}
			if (anyNeeded && !r.suggestedMove?.needed) {
				return false;
			}
			if (anyMine()) {
				return false;
			}
			if (r.suggestedMove?.needed && !myTurn()) {
				return true;
			}
			return (
				i < MIN_TRUNCATED ||
				(myTurn() && r.score && r.score > 0) ||
				moveHasTag(r, MoveTag.RareDangerous) ||
				(myTurn() && moveHasTag(r, MoveTag.Transposes))
			);
		}) as TableResponse[];
		const numTruncated = props.responses().length - trimmedResponses.length;
		const truncated = numTruncated > 0;
		return {
			trimmedResponses,
			truncated,
		};
	});

	const previousLineLength = createPrevious(() => currentLine().length);
	createEffect(() => {
		if (previousLineLength() !== currentLine().length) {
			setExpandedLength(0);
		}
	});
	const openingNames = createMemo(() =>
		reverse(
			map(reverse([...trimmedResponses()]), (tr) => {
				let openingName = BROWSING_STATE().getOpeningNameForMove(tr.suggestedMove?.epdAfter!);
				return openingName;
			}),
		),
	);
	const myMove = createMemo(() => {
		if (!myTurn()) {
			return null;
		}
		return trimmedResponses()[0]?.repertoireMove;
	});

	const columns = () =>
		getMovesTableColumnsRight({
			myTurn: myTurn(),
			usePeerRates: props.usePeerRates(),
			allLocalStockfish: props.allLocalStockfish,
			repertoire: props.repertoire,
		});
	const overrideRightColumns: Accessor<SidebarTableColumn<TableResponseRow>[]> = () => {
		if (editingAnnotations()) {
			return [
				{
					label: "",
					labelStyle: "hidden",
					width: 120,
					align: "right",
					render: (_columnProps) => {
						return (
							<div class="flex row space-x-2 text-tertiary text-xs group-hover:text-primary transition-colors">
								<p class="">Annotate</p>
								<i class="fa-solid fa-pen"></i>
							</div>
						);
					},
				},
			];
		}
		if (excludingMoves()) {
			return [
				{
					label: "Excluded",
					labelStyle: "hidden",
					width: 120,
					align: "right",
					render: (columnProps) => {
						const excluded = columnProps.tableResponse().repertoireMove?.isExcluded;
						return (
							<div class="flex row items-center space-x-2 text-secondary text-xs group-hover:text-primary transition-colors">
								<p class="">{excluded ? "Include" : "Exclude"}</p>
								<i class={clsx(excluded ? "fa-solid fa-check" : "fa-solid fa-ban")}></i>
							</div>
						);
					},
				},
			];
		}
		return [];
	};
	const leftColumns = () => getMovesTableColumnsLeft();
	const currentEpd = createMemo(() => {
		if (props.epd) {
			return props.epd;
		}
		if (mode() === "review") {
			return REVIEW_STATE().chessboard.getCurrentEpd();
		}
		return BROWSING_STATE().chessboard.getCurrentEpd();
	});
	const positionReport = () =>
		REPERTOIRE_STATE().positionReports?.[activeRepertoireId()!]?.[currentEpd()];
	const currentSide = () => BROWSING_STATE().currentSide;
	const memoizedTrimmedResponses = createMemo(
		() => {
			return trimmedResponses();
		},
		undefined,
		{ equals: (a, b) => isEqual(a, b) },
	);
	const tableResponseRows: Accessor<TableResponseRow[]> = createMemo(() => {
		return memoizedTrimmedResponses().map((tr, i) => {
			const openingName = () => openingNames()[i];
			const hideAnnotations = () => {
				if (mode() !== "review") {
					return moveNumber() === 1 && openingName;
				}
				if (mode() === "review") {
					return tr.reviewInfo?.hideAnnotations;
				}
			};
			const annotation = createMemo(() => {
				if (hideAnnotations()) {
					return null;
				}
				const san = TableResponse.getSan(tr);

				const annotation =
					tr.suggestedMove?.annotation ||
					REPERTOIRE_STATE().getAnnotation(Kep.toKey({ epd: currentEpd(), san }), {
						repertoireId: activeRepertoireId(),
					})?.text;
				if (!annotation) {
					return null;
				}
				return renderAnnotation(annotation);
			});
			const [numMovesDueFromHere, earliestDueDate] = destructure(() => {
				if (mode() !== "browse") {
					return [0, undefined];
				}
				const epdAfter = tr.repertoireMove?.epdAfter;
				if (!epdAfter) {
					return [0, undefined];
				}
				return [
					REPERTOIRE_STATE().numMovesDueFromEpd[activeRepertoireId()!][epdAfter],
					REPERTOIRE_STATE().earliestReviewDueFromEpd[activeRepertoireId()!][epdAfter],
				];
			});

			const isFaded = () => tr.repertoireMove?.isDisabled ?? false;
			const unclickable = () =>
				(tr.repertoireMove?.isDisabled && mode() === "browse") || props.unclickable === true;
			const tags = () => {
				const tags: JSXElement[] = [];
				if (moveHasTag(tr, MoveTag.BestMove)) {
					tags.push(
						<MoveTagView
							tip={
								<p>
									This is the best move according to masters, the computer, and results at your
									level
								</p>
							}
							text="Clear best move"
							icon="fa-duotone fa-trophy"
							style={stylex(c.fg(c.yellow[60]), c.fontSize(14))}
						/>,
					);
				}
				if (
					moveHasTag(tr, MoveTag.Sharpest) &&
					tr.moveRating !== MoveRating.Mistake &&
					tr.moveRating !== MoveRating.Blunder
				) {
					tags.push(
						<MoveTagView
							tipWidth={260}
							tip={
								<p>
									This is the sharpest continuation, according to Leela Chess Zero.
									<br />
									<br />
									If you want complicated, tactical positions, this may be a good move.
								</p>
							}
							text="Sharpest line"
							icon="fas fa-swords text-primary fa-duotone"
							style={stylex(c.duotone(c.colors.gray[70], c.colors.gray[90]), c.fontSize(14))}
						/>,
					);
				}
				// if (props.tableResponse.stockfishMove) {
				// 	const thinking = !props.tableResponse.stockfishMove.final;
				// 	tags.push(
				// 		<MoveTagView
				// 			tip={<p>A local version of Stockfish 16 has generated this eval.</p>}
				// 			text="Local Stockfish"
				// 			icon={clsx(
				// 				"fa-duotone fa-robot",
				// 				thinking && "animate-pulse animate-duration-1000 transition-all",
				// 			)}
				// 			style={stylex(
				// 				c.fg(thinking ? c.gray[60] : c.blue[60]),
				// 				c.fontSize(14),
				// 			)}
				// 		/>,
				// 	);
				// }
				if (positionReport()) {
					const playRate = TableResponse.getPlayRate(tr, positionReport(), {
						usePeerRates: props.usePeerRates() ?? false,
					});
					if (
						tr.suggestedMove?.intuitive &&
						(tr.moveRating === MoveRating.Good || isNil(tr.moveRating)) &&
						playRate &&
						playRate > 0.05
					) {
						tags.push(
							<MoveTagView
								tip={
									<p>
										This move should be easy to remember because it's similar to the rest of your
										repertoire
									</p>
								}
								text="Consistent with your repertoire"
								icon="fa-regular fa-diagram-venn text-purple-65"
								style={stylex(c.fontSize(14))}
							/>,
						);
					}
				}
				if (moveHasTag(tr, MoveTag.Transposes)) {
					tags.push(
						<MoveTagView
							tip={
								<p>
									This move is an alternative way to reach an existing position in your repertoire
								</p>
							}
							text="Transposes to your repertoire"
							icon="fa-solid fa-merge"
							style={stylex(c.fg(c.colors.success), c.fontSize(14), c.rotate(-90))}
						/>,
					);
				}
				if (moveHasTag(tr, MoveTag.TheoryHeavy)) {
					tags.push(
						<MoveTagView
							tip={
								<p>
									This opening requires a lot of memorization so is not recommended for beginners
								</p>
							}
							text="Warning: heavy theory"
							icon="fa-solid fa-triangle-exclamation"
							style={stylex(c.fg(c.red[60]), c.fontSize(14))}
						/>,
					);
				}
				if (tr.repertoireMove?.courseId && tr.repertoireMove?.mine) {
					let courseOverview = REPERTOIRE_STATE().courses[tr.repertoireMove.courseId];
					if (courseOverview) {
						tags.push(
							<div
								class="flex row gap-2 items-center"
								ref={(ref) => {
									initTooltip({
										ref,
										content: () => (
											<p>
												You added this move as part of <b>{courseOverview?.name}</b> pre-made
												repertoire
											</p>
										),
										maxWidth: 240,
									});
								}}
							>
								<CourseAvatar course={courseOverview} size={20} />
								<p class="">{courseOverview?.name}</p>
							</div>,
						);
					}
				}
				if (tr.reviewInfo?.learning) {
					tags.push(
						<MoveTagView
							tip={
								<p>
									We're showing a reminder arrow since this is your first time practicing this move.
								</p>
							}
							text="First practice"
							icon="fa fa-graduation-cap"
							style={stylex(c.fg(c.orange[55]), c.fontSize(14))}
						/>,
					);
				}
				if (moveHasTag(tr, MoveTag.RareDangerous)) {
					tags.push(
						<MoveTagView
							tip={
								<p>
									This move is seen in less than{" "}
									<b>
										{renderThreshold(
											REPERTOIRE_STATE().getRepertoireThreshold(activeRepertoireId()),
										)}{" "}
										games{" "}
									</b>
									but the high win-rate for {currentSide()} means you should still prepare for it
								</p>
							}
							text={`Rare but dangerous`}
							icon="fa fa-radiation"
							style={stylex(c.fg(c.red[65]), c.fontSize(18))}
						/>,
					);
				}
				if (moveHasTag(tr, MoveTag.CommonMistake)) {
					tags.push(
						<MoveTagView
							tip={
								<p>
									This is a bad move that's common at your level, so you should be prepared to
									punish it
								</p>
							}
							text="Common mistake"
							icon="fa fa-person-falling"
							style={stylex(c.fg(c.gray[80]), c.fontSize(14))}
						/>,
					);
				}
				return tags;
			};
			const tagsRow = () =>
				!isEmpty(tags()) && (
					<div style={stylex(c.grow, c.row, c.flexWrap, c.justifyStart, c.gap(4))} class="gap-4">
						<For each={tags()}>
							{(tag) => {
								return tag;
							}}
						</For>
					</div>
				);
			let tableResponseRow: TableResponseRow = {
				unclickable,
				isFaded,
				numMovesDueFromHere,
				earliestDueDate,
				suggestedMove: () => tr.suggestedMove,
				positionReport,
				tableResponse: () => tr,
				side: currentSide,
				line: currentLine,
				epd: currentEpd,
				courseStats: () => undefined,
				annotation: () => annotation() ?? undefined,
				tagsRow: tagsRow,
				openingName: openingName,
			};
			return tableResponseRow;
		});
	});
	const footerActions = createMemo(() => {
		const actions: {
			text: string;
			onPress: (e: Event) => void;
			icon?: string;
		}[] = [];

		if (truncated() && mode() === "build" && !(onboarding().isOnboarding && !myTurn())) {
			actions.push({
				text: firstWhiteMove() ? "Something else..." : "Show more moves",
				onPress: (e: Event) => {
					e.preventDefault();
					e.stopPropagation();
					setExpandedLength(trimmedResponses().length + 5);
				},
			});
		}
		if (
			(mode() === "build" &&
				moveNumber() !== 1 &&
				!onboarding().isOnboarding &&
				!excludingMoves()) ||
			props.canAnnotate
		) {
			let text = "Add / edit annotations";
			let firstAnnotation = tableResponseRows()[0].annotation();
			if (tableResponseRows().length === 1) {
				if (mode() === "review") {
					if (firstAnnotation) {
						text = `Edit annotation`;
					} else {
						text = "Add a note to explain this move";
					}
				}
				if (firstAnnotation) {
					text = `Edit annotation`;
				} else {
					text = `Add annotation`;
				}
			}
			if (editingAnnotations()) {
				text = "Stop annotating";
			}
			actions.push({
				text,
				onPress: () => {
					if (!editingAnnotations()) {
						trackEvent(`${mode()}.moves_table.edit_annotations`);
					}
					if (trimmedResponses().length > 1) {
						setEditingAnnotations(!editingAnnotations());
					} else {
						UI().pushView(AnnotationEditSidebar, {
							props: {
								san: TableResponse.getSan(trimmedResponses()[0]),
								epd: currentEpd(),
							},
						});
					}
				},
			});
		}
		if (
			mode() === "build" &&
			!onboarding().isOnboarding &&
			!editingAnnotations() &&
			USER_STATE().flagEnabled("move_exclusion") &&
			!myTurn()
		) {
			let text = "Exclude specific moves";
			if (excludingMoves()) {
				text = "Stop excluding moves";
			}
			actions.push({
				text,
				onPress: () => {
					if (!excludingMoves()) {
						trackEvent(`${mode()}.moves_table.exclude_moves`);
					}
					setExcludingMoves(!excludingMoves());
				},
			});
		}
		if (anyMine() && mode() === "build" && !editingAnnotations() && !excludingMoves()) {
			actions.push({
				onPress: () => {
					trackEvent(`${mode()}.moves_table.delete_move`);
					quick((s) => {
						animateSidebar("right");
						UI().pushView(DeleteLineView);
						s.repertoireState.browsingState.deleteLineState.move = myMove()!;
					});
				},
				text: "Remove",
				icon: "fa-solid fa-trash",
			});
		}
		if (anyMine() && mode() === "build" && !editingAnnotations() && !excludingMoves()) {
			actions.push({
				onPress: () => {
					trackEvent(`${mode()}.moves_table.disable_move`);
					quick((s) => {
						if (myMove()?.isDisabled) {
							s.repertoireState.toggleMove(
								myMove()!,
								false,
								[...currentLine(), myMove()!.sanPlus],
								activeRepertoireId(),
							);
						} else {
							s.repertoireState.browsingState.chessboard.makeMove(myMove()!.sanPlus, {
								animate: true,
								sound: "move",
							});
							UI().pushView(DisableMoveConfirmation, {
								props: {
									move: myMove()!,
									disable: !myMove()?.isDisabled,
								},
							});
						}
					});
				},
				text: myMove()?.isDisabled ? "Re-enable" : "Disable",
				icon: myMove()?.isDisabled ? "fa-solid fa-play" : "fa-solid fa-pause",
			});
		}

		return actions;
	});
	const chessboard = () => REPERTOIRE_STATE().getChessboard();
	const onPositionUpdate = () => {
		setEditingAnnotations(false);
		setExcludingMoves(false);
	};
	onMount(() => {
		chessboard().set((c) => {
			c.onPositionUpdate.add(onPositionUpdate);
		});
	});
	onCleanup(() => {
		chessboard().set((c) => {
			c.onPositionUpdate.remove(onPositionUpdate);
		});
	});
	const onHover = (tableResponseRow?: TableResponseRow) => {
		if (mode() === "review") {
			return;
		}
		if (tableResponseRow) {
			APP_STATE().repertoireState.browsingState.chessboard?.previewMove(
				TableResponse.getSan(tableResponseRow.tableResponse()),
			);
		} else {
			APP_STATE().repertoireState.browsingState.chessboard?.previewMove(null);
		}
	};
	return (
		<div style={stylex(c.column)}>
			<SidebarTable
				isFaded={(trr) => trr.isFaded()}
				isClickable={(trr) => !trr.unclickable()}
				rows={tableResponseRows()}
				onHover={(trr) => onHover(trr)}
				onBlur={(_trr) => onHover()}
				onClick={(trr) => {
					quick((s) => {
						if (editingAnnotations()) {
							UI().pushView(AnnotationEditSidebar, {
								props: {
									san: TableResponse.getSan(trr.tableResponse()),
									epd: currentEpd(),
								},
							});
							return;
						}
						if (excludingMoves()) {
							props.setLoading?.(true);
							quick((s) => {
								let move: Omit<RepertoireMoveDTO, "id"> = {
									sanPlus: TableResponse.getSan(trr.tableResponse()),
									epd: currentEpd(),
									epdAfter: TableResponse.getEpdAfter(trr.tableResponse())!,
									isExcluded: !trr.tableResponse().repertoireMove?.isExcluded,
									mine: false,
									side: trr.tableResponse().side,
								};
								s.repertoireState.updateMove(move, activeRepertoireId()).then(() => {
									props.setLoading?.(false);
								});
							});
							return;
						}
						if (mode() === "review") {
							return;
						}
						animateSidebar("right");
						s.repertoireState.browsingState.chessboard.makeMove(
							TableResponse.getSan(trr.tableResponse()),
							{
								animate: true,
								sound: "move",
							},
						);
						if (trr.tableResponse().transposes) {
							UI().pushView(TransposedView);
						}
					});
				}}
				rightColumns={columns()}
				overrideRightColumns={overrideRightColumns()}
				leftColumns={leftColumns()}
				renderBelow={(trr) => {
					return (
						<Show when={trr.annotation() && isMobile()}>
							<p class="text-secondary text-xs  leading-5">{trr.annotation()}</p>
						</Show>
					);
				}}
				maxRows={null}
			/>
			<Show when={!isEmpty(footerActions())}>
				<div
					style={stylex(c.row, c.px(c.getSidebarPadding(responsive())))}
					class={clsx("pt-3 space-x-4")}
				>
					<For each={footerActions()}>
						{(action) => (
							<Pressable
								class={clsx("pb-1")}
								onPress={(e: Event) => {
									action.onPress(e);
								}}
							>
								<CMText
									style={stylex(c.fontSize(12), c.weightSemiBold)}
									class="text-tertiary &hover:text-primary transition-colors"
								>
									{action.text}
									<Show when={action.icon}>
										<i class={clsx("ml-2 text-[10px]", action.icon)} />
									</Show>
								</CMText>
							</Pressable>
						)}
					</For>
				</div>
			</Show>
		</div>
	);
};

const MoveTagView = (props: {
	icon: string;
	text: string;
	style: any;
	tipWidth?: number;
	tip: JSXElement;
	class?: string;
}) => {
	return (
		<p
			style={stylex(c.fontSize(10), c.weightBold, c.row, c.alignCenter)}
			class={clsx(props.class ?? "text-gray-80")}
			ref={(ref) => {
				initTooltip({
					ref,
					content: () => {
						return props.tip;
					},
					maxWidth: props.tipWidth ?? 200,
				});
			}}
		>
			<i class={clsx(props.icon, "shrink-0")} style={stylex(props.style)} />
			<Spacer width={8} />
			{props.text}
		</p>
	);
};

const moveHasTag = (m: TableResponse, tag: MoveTag): boolean => {
	return includes(m.tags, tag);
};
