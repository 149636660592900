import { For } from "solid-js";
import { clsx } from "~/utils/classes";
import { colors } from "~/utils/design-system";
import { HomePageCTA } from "./HomePageCTA";
import { HomePageHeader, HomePageSubheader } from "./HomePageHeaders";
import { HomePageImage } from "./HomePageImage";
import { HomePageStickyNav } from "./HomePageStickyNav";

const sectionClasses = clsx("max-w-[1000px] mx-auto w-[90%] py-6 md:py-6");
const headerClasses = "text-xl md:text-[32px] font-bold mb-4 text-white !leading-[36px]";
const featureBodyClasses =
	"text-base md:text-lg text-tertiary !leading-normal text-[#778288] font-light";

const SideBySideFeature = (props: {
	image: string;
	header: string;
	body: string;
	direction: "left" | "right";
}) => {
	return (
		<section class={clsx(sectionClasses)}>
			<div>
				<div
					class={clsx(
						"flex flex-col md:flex-row items-center text-center md:text-left gap-8 md:gap-18",
						props.direction === "left" && "md:flex-row-reverse",
					)}
				>
					<div class="w-full lg:w-1/2">
						<img
							src={props.image}
							alt={props.header}
							class="w-full h-auto object-cover rounded-2xl "
							style={{
								"box-shadow": "rgba(0, 0, 0, 0.5) 0px 10px 30px 0px",
							}}
						/>
					</div>
					<div class="w-full lg:w-1/2 flex flex-col justify-center">
						<h2 class={clsx(headerClasses)}>{props.header}</h2>
						<p class={featureBodyClasses}>{props.body}</p>
					</div>
				</div>
			</div>
		</section>
	);
};

const faqData: { question: string; answer: Element }[] = [
	{
		question: 'What is an "opening repertoire"?',
		answer: (
			<>
				An <strong>opening repertoire</strong> is a set of pre-planned moves for the early part of a
				chess game. A complete repertoire will include responses to all of the opponent's potential
				moves during this phase of the game.
			</>
		) as Element,
	},
	{
		question: "What's the benefit of having one?",
		answer: (
			<>
				Having a well constructed repertoire that fits with your style can be a huge advantage. It
				can let you steer the game toward positions you are comfortable in and enjoy playing,
				improving your results.
			</>
		) as Element,
	},
	{
		question: 'What\'s the difference between an "opening repertoire" and an "opening"?',
		answer: (
			<>
				An <strong>opening repertoire</strong> consists of <span>multiple</span>{" "}
				<strong>openings</strong> to combat different moves by the opponent. For example an e4
				player will need to learn different openings to deal with the Sicilian, French, Caro-Kann
				etc.
			</>
		) as Element,
	},
	{
		question: 'What\'s the difference between an "opening" and a "line"?',
		answer: (
			<>
				A <strong>line</strong> is a sequence of moves, one after another. An{" "}
				<strong>opening</strong> is made up of many lines. For example, someone who plays the
				Sicilian Dragon will need to learn many individual lines to master that opening.
			</>
		) as Element,
	},
	{
		question: "I'm new to chess, do I need an opening repertoire?",
		answer: (
			<>
				While having a solid repertoire becomes more important as you improve, even beginners can
				benefit from having a simple opening repertoire that prepares them for common moves at their
				level.
			</>
		) as Element,
	},
	{
		question: "What's involved in learning a repertoire?",
		answer: (
			<>
				Learning a repertoire generally consists of choosing which openings and lines to play,
				memorising specific sequences of moves and understanding why those moves are played.
			</>
		) as Element,
	},
];

const FAQItem = (props: { question: string; answer: Element }) => (
	<div class="w-full ">
		<div class="flex flex-col h-full">
			<h3 class="text-base md:text-xl font-medium mb-4 text-white !leading-normal">
				{props.question}
			</h3>
			<p class="text-tertiary md:text-lg !leading-normal">{props.answer}</p>
		</div>
	</div>
);

const pricingData = [
	{
		title: "Starter",
		price: <div class="flex gap-0 flex-row">Free</div>,
		period: "forever",
		description: "Build a repertoire of up to 200 moves.",
		buttonText: "Get started",
		source: "free_get_started",
		footnote: "No signup required",
	},
	{
		title: "Pro",
		price: (
			<p class="relative">
				<span class="text-gray-10 font-bold text-3xl bottom-[20px] relative">$</span>
				<span class="text-gray-10 text-6xl">7</span>
			</p>
		),
		period: "per month, billed annually",
		description: "Add unlimited moves to any depth.",
		buttonText: "Try it for free",
		source: "pro_try_it_for_free",
		footnote: "No signup required",
	},
];

const PricingCard = ({
	title,
	price,
	period,
	description,
	buttonText,
	onClick,
	footnote,
	source,
}) => (
	<div class="bg-white rounded-lg p-8 md:py-14 text-gray-10 flex items-center gap-4 grow">
		<h3 class="text-lg md:text-2xl font-semibold mb-4">{title}</h3>
		<p class="text-4xl md:text-6xl font-bold">{price}</p>
		<p class="text-tertiary mb-2 md:mb-4">{period}</p>
		<p class="text-tertiary mb-2 md:mb-6">{description}</p>
		<a
			href="#"
			class="bg-orangeBright-60 text-gray-10 transition-all hover:bg-orange-45 font-normal py-3  px-6 rounded-lg transition duration-300 inline-block text-xl"
			onClick={() => onClick(source)}
		>
			{buttonText}
		</a>
		<p class="text-xs text-tertiary -mt-2 font-light">{footnote}</p>
	</div>
);

const TopDownFeature = (props: {
	image: string;
	header: string;
	body: string;
}) => {
	return (
		<section class="py-16 px-6 ">
			<div class="max-w-6xl mx-auto">
				<div class={clsx("flex flex-col items-center gap-6 md:gap-12")}>
					<div class="w-full flex flex-col justify-center items-center text-center">
						<h2 class={headerClasses}>{props.header}</h2>
						<p class={featureBodyClasses}>{props.body}</p>
					</div>
					<div class="w-full">
						<img
							src={props.image}
							alt={props.header}
							class="w-full h-auto object-cover rounded-lg "
							style={{
								"box-shadow": "rgba(0, 0, 0, 0.6) 0px 5px 30px 0px",
							}}
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

const testimonials = [
	{
		text: (
			<>
				A fun and simple way to build an opening repertoire based on probabilities of most likely
				lines.
				<br />
				<br />
				You will actually learn the moves that will happen on the board!
			</>
		),
		name: "Eugene Perelshteyn",
		title: "Grandmaster",
		rating: "2507 FIDE",
		image: "/homepage_imgs/eugene.jpg?v=2024-09-19",
	},
	{
		text: (
			<>
				The only tool I use for tournament prep now. Drilling variations before games just became
				easy. <br /> <br />
				Priceless for building and easily studying a repertoire, and it works for all levels!
			</>
		),
		name: "Stjepan Tomic",
		title: "Hanging Pawns",
		rating: "1945 FIDE",
		image: "/homepage_imgs/hanging_pawns.jpeg?v=2024-09-19",
	},
	{
		text: (
			<>
				Absolutely amazing and unlike what anybody else has developed.
				<br />
				<br />
				It's exactly what I've wanted since I started playing seriously.
			</>
		),
		name: "Jon Myers",
		title: "Adult improver",
		rating: "1275 Chess.com",
		image: "",
	},
];

const Testimonial = ({ text, name, title, rating, image }) => (
	<div class="bg-white rounded-2xl shadow-xl px-6 py-6 md:p-6 sm:p-4 flex flex-col items-start basis-0 grow">
		<p class="text-gray-40 text-lg mb-4 md:mb-9 sm:mb-8 flex-1 leading-normal font-normal">
			"{text}"
		</p>
		<div class="flex flex-row md:flex-col items-start">
			{image && (
				<img
					src={image}
					alt={name}
					class="w-10 h-10 rounded-full mr-4 mb-3 md:mb-2 sm:w-9 sm:h-9 border-1 border-solid border-gray-20"
				/>
			)}
			<div>
				<p class="text-blue-50 text-base font-regular leading-normal">
					{name}
					<br />
					{title}
					<br />
					<span class="text-gray-500">{rating}</span>
				</p>
			</div>
		</div>
	</div>
);

const LandingPage = (props: { onClick: (s: string) => void; onLogin: () => void }) => {
	const features = [
		{
			image: "/homepage_imgs/coverage.png?v=2023-04-26T12:10:10.140Z",
			header: "Find the gaps in your repertoire before your opponents do",
			body: "Chessbook calculates your coverage per opening so you always know what to work on.",
		},
		{
			image: "/homepage_imgs/move_stats.png?v=2023-04-26T12:10:26.979Z",
			header: "Pick the best moves to maximize your win-rate",
			body: "Weigh up your options by looking at stats from master games, engine evaluation and results at your level.",
		},
		{
			image: "/homepage_imgs/incidence.png?v=2023-04-02T09:04:04.254Z",
			header: "Only spend time on the moves you'll actually see",
			body: "Most books and courses are written by masters for masters. Chessbook lets you avoid obscure grandmaster lines and focus your effort on the moves that are common at your level.",
		},
	];

	const topDownFeatures = [
		{
			header: "Learn middlegame plans for any opening",
			body: "See how top players handle the positions that result from the openings you play.",
			image: "/homepage_imgs/how-to-play.png?v=2023-04-24T02:19:18.668Z",
		},
		{
			header: "Find mistakes in your online games",
			body: "Connect your Chess.com or Lichess account to get instant feedback when you deviate from your repertoire.",
			image: "/homepage_imgs/mistakes.png",
		},
		{
			header: "Play through model games that follow your repertoire",
			body: `See how masters handle the openings you play and "guess the move" as you go.`,
			image: "/homepage_imgs/model_games.png",
		},
	];

	return (
		<div class="bg-gray-8 text-white">
			<HomePageStickyNav onClick={() => props.onClick("sticky-homepage-cta")} />
			{/* Header */}
			<header class={clsx(sectionClasses)}>
				<div class={clsx("py-3 flex flex-row justify-between items-center w-full h-[75px]")}>
					<img src="/homepage_imgs/chessbook.svg" alt="Chessbook" class="h-5 md:h-6" />
					<nav class="flex gap-2 text-tertiary text-lg transition-colors font-light flex-row h-[18px]">
						<div class="hover:text-primary cursor-pointer " onClick={() => props.onLogin()}>
							Log in
						</div>
						<div class="w-[1px] h-full bg-gray-40 self-stretch" />
						<div class="hover:text-primary cursor-pointer" onClick={() => props.onClick("signup")}>
							Sign up
						</div>
					</nav>
				</div>
			</header>

			<div class="bg-gray-15">
				{/* Hero Section */}
				<section
					class={clsx("text-center flex flex-col pt-16 md:pt-18 lg:pt-15")}
					style={{
						background: `${colors.gray[8]} url("/homepage_imgs/hero-illustration.png") 0% 0% /100% repeat scroll padding-box`,
					}}
				>
					<div class={clsx(sectionClasses, "!p-0")}>
						<h1 class={clsx("text-4xl md:text-[56px] xl:text-6xl font-bold mb-5 md:mb-4")}>
							<HomePageHeader />
						</h1>
						<p
							class={clsx(
								"text-lg md:text-xl  text-secondary font-medium leading-normal mb-9 md:mb-6 lg:mb-6",
								sectionClasses,
							)}
						>
							<HomePageSubheader />
						</p>
						<div class="">
							<HomePageCTA onClick={() => props.onClick("splash_cta")} />
							<p class="mt-2 text-sm text-tertiary">No signup required</p>
						</div>
						<div
							class={clsx(
								"flex row justify-center gap-8 max-w-[1000px] self-center md:!py-9",
								sectionClasses,
							)}
						>
							<a
								href="https://apps.apple.com/us/app/chessbook/id6466343415"
								target="_blank"
								rel="noreferrer"
								onClick={() => props.onClick("home.app_store_clicked")}
							>
								<img
									src="/homepage_imgs/app-store.svg"
									alt="App Store"
									class="h-12 max-w-[120px]"
								/>
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=com.chessbook.android"
								target="_blank"
								rel="noreferrer"
								onClick={() => props.onClick("home.play_store_clicked")}
							>
								<img
									src="/homepage_imgs/play-store.svg"
									alt="Play Store"
									class="h-12 max-w-[120px]"
								/>
							</a>
						</div>
						<div class={clsx(" self-center")}>
							<HomePageImage />
						</div>
					</div>
				</section>

				<div
					class="px-6 flex gap-8 xl:gap-32 py-8 md:py-25 xl:py-25 bg-gray-10"
					style={{ "background-color": "hsl(193, 7%, 14%)" }}
				>
					{/* Feature Sections */}
					<For each={features}>
						{(feature, index) => (
							<SideBySideFeature
								image={feature.image}
								header={feature.header}
								body={feature.body}
								direction={index() % 2 === 0 ? "left" : "right"}
							/>
						)}
					</For>
				</div>

				<section
					class="py-16 md:py-24 px-6 bg-gray-8 bg-opacity-50 bg-cover bg-center "
					style={{
						"background-image": ` url(/homepage_imgs/bobby.png)`,
					}}
				>
					<div
						class={clsx(
							"max-w-[1000px] mx-auto flex flex-col md:flex-row items-center gap-10 flex",
							sectionClasses,
						)}
					>
						<div class="mb-8 md:mb-0">
							<img
								src="/homepage_imgs/mobile.png"
								alt="Mobile app"
								class="w-full max-w-sm mx-auto"
							/>
						</div>
						<div class="md:max-w-1/2 md:pl-8">
							<h2 class="text-xl md:text-3xl font-bold mb-6 !leading-[34px] text-center md:text-left">
								Targeted practice means you'll never forget a move
							</h2>
							<p class="md:text-lg mb-4 text-secondary !leading-normal text-center md:text-left ">
								Chessbook uses <strong>spaced repetition</strong>, a scientifically proven technique
								to memorize openings quickly and thoroughly.
							</p>
						</div>
					</div>
				</section>

				<div class={clsx(" text-center homepage-section", sectionClasses, "!py-16")}>
					<h2 class="text-xl md:text-[32px] font-bold mb-4 md:mb-4">
						Collect all your openings in one place
					</h2>
					<p class="md:text-lg mb-15 md:mb-18 text-[#778288] !leading-normal">
						Nobody gets their whole repertoire from a single course or book. Chessbook lets you
						combine openings from multiple sources to create a custom repertoire just for you.
					</p>
					<img src="/homepage_imgs/diagram.svg" alt="Diagram" class="w-full" />
				</div>

				<div style={{ "background-color": "hsl(193, 7%, 14%)" }}>
					<For each={topDownFeatures}>
						{(feature, _index) => (
							<TopDownFeature image={feature.image} header={feature.header} body={feature.body} />
						)}
					</For>
				</div>

				{/* Testimonials */}
				<section
					class="py-16 px-6 bg-gray-8 bg-opacity-50 bg-cover bg-center"
					style="background-image: url('/homepage_imgs/pexels-cottonbro-studio.jpg')"
				>
					<div class="max-w-[1000px] mx-auto">
						<h2 class={clsx(headerClasses, "mb-7 md:mb-12 text-center")}>
							Endorsed by masters, loved by adult improvers
						</h2>
						<div class="flex flex-col md:flex-row gap-8">
							{/* Testimonial cards */}
							<For each={testimonials}>
								{(testimonial) => (
									<Testimonial
										text={testimonial.text}
										name={testimonial.name}
										title={testimonial.title}
										rating={testimonial.rating}
										image={testimonial.image}
									/>
								)}
							</For>
						</div>
					</div>
				</section>

				{/* Pricing */}
				<section
					style={{
						background: `hsl(333, 6%, 8%) url("/homepage_imgs/hero-illustration.png") 0% 0% /100% repeat scroll padding-box`,
					}}
				>
					<div class={clsx(sectionClasses, "flex items-center my-6 md:my-12")}>
						<h2 class={clsx(headerClasses, "mb-3 md:mb-4")}>
							Get started for free, no signup required
						</h2>
						<p class={clsx(featureBodyClasses, "mb-9 md:mb-12 ")}>
							Create a simple repertoire in minutes with our free starter plan.
						</p>
						<div class="grid md:grid-cols-2  gap-4 self-stretch">
							<For each={pricingData}>
								{(card, _index) => <PricingCard {...card} onClick={props.onClick} />}
							</For>
						</div>
					</div>
				</section>
			</div>

			{/* FAQ */}
			<section class="py-16 bg-gray-14">
				<div class={clsx(sectionClasses, "flex items-center max-w-[1400px]")}>
					<h2 class={clsx(headerClasses, "mb-9 md:mb-12")}>Frequently asked questions</h2>
					<div class="grid md:grid-cols-2 xl:grid-cols-3 gap-8 w-full md:gap-y-12">
						<For each={faqData}>
							{(faq) => <FAQItem question={faq.question} answer={faq.answer} />}
						</For>
					</div>
				</div>
			</section>

			{/* Footer */}
			<footer class="bg-gray-16 text-center py-8">
				<a href="https://discord.gg/vNzfu5VetQ" class="inline-block mb-4">
					<img
						src="/homepage_imgs/discord-logo-white.svg"
						alt="Discord"
						class="h-5 md:h-8 opacity-50"
					/>
				</a>
				<p class="text-sm text-tertiary">
					Questions? Feedback? Ideas? Join the{" "}
					<a
						href="https://discord.gg/vNzfu5VetQ"
						class="text-secondary hover:text-primary leading-normal"
					>
						Chessbook Discord server
					</a>
				</p>
			</footer>
		</div>
	);
};

export default LandingPage;
