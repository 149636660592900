import { some, sortBy } from "lodash-es";
import { filter, isNil, noop } from "lodash-es";

import {} from "~/components/SidebarOnboarding";
import type { ChessCardDTO } from "~/rspc";

import {} from "~/types/Repertoire";
import type {} from "~/types/RepertoireGrade";
import type {} from "~/types/RepertoireMove";
import {} from "~/types/Side";
import { SpacedRepetitionStatus } from "~/types/SpacedRepetition";

import {} from "~/types/kep";

import type { AppState } from "./app_state";

import {} from "./env";

import type { Move } from "@lubert/chess.ts";
import { getInitialCardReviewState } from "./cards_review_state";
import { type ChessboardInterface, createChessboardInterface } from "./chessboard_interface";
import { rspcClient } from "./rspc_client";
import type { StateGetter, StateSetter } from "./state_setters_getters";

export type CardsState = ReturnType<typeof getInitialChesscardsState>;

type Stack = [CardsState, AppState];
const selector = (s: AppState): Stack => [s.cardsState, s];

export const getInitialChesscardsState = (
	_setDoNotUse: StateSetter<AppState, any>,
	_getDoNotUse: StateGetter<AppState, any>,
) => {
	const set = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _setDoNotUse((s) => fn(selector(s)));
	};
	const get = <T,>(fn: (stack: Stack) => T, _id?: string): T => {
		return _getDoNotUse((s) => fn(selector(s)));
	};
	const initialState = {
		cards: undefined as ChessCardDTO[] | undefined,
		chessboardOverview: undefined as unknown as ChessboardInterface,
		reviewState: getInitialCardReviewState(),
		chessboardBuild: undefined as unknown as ChessboardInterface,
		totalDue: 0 as number,
		processingState: {
			isFetching: false,
			numProcessing: 0,
		},
		earliestDue: undefined as string | undefined,
		deleteCard: (card: ChessCardDTO) => {
			set(([s]) => {
				if (!s.cards) {
					return;
				}
				rspcClient.query(["cards.deleteCard", { cardId: card.id }]);
				s.cards = filter(s.cards, (c) => c.id !== card.id);
				s.onCardsUpdate();
			});
		},
		addCard: (card: ChessCardDTO) => {
			set(([s]) => {
				if (!s.cards) {
					return;
				}
				if (some(s.cards, (c) => c.id === card.id)) {
					return;
				}
				s.cards?.push(card);
				s.onCardsUpdate();
			});
		},
		onCardsUpdate: () => {
			set(([s]) => {
				if (!s.cards) {
					return;
				}
				s.totalDue = filter(
					s.cards,
					(c) => SpacedRepetitionStatus.isReviewDue(c.srs) && !c.srs.firstReview,
				).length;
				s.earliestDue =
					sortBy(s.cards.map((c) => c.srs?.dueAt).filter(isNil), (x) => x!)[0] ?? undefined;
			});
		},
		initState: () => {
			set(([s, _gs]) => {
				s.fetchCards();
			});
		},
		fetchCards: () => {
			rspcClient.query(["cards.fetchCards", {} as unknown as null]).then((chessCards) => {
				set(([s]) => {
					s.cards = chessCards;
					s.onCardsUpdate();
				});
			});
		},
	};

	initialState.chessboardOverview = createChessboardInterface()[1];
	initialState.chessboardOverview.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {
				set(([_s]) => {});
			},

			madeManualMove: () => {
				get(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {});
			},
			onReset: () => {
				set(([_s]) => {});
			},
			onMovePlayed: () => {
				set(([_s, _rs]) => {});
			},
			shouldMakeMove: (_move: Move) =>
				set(([_s]) => {
					return true;
				}),
		};
	});
	initialState.chessboardBuild = createChessboardInterface()[1];
	initialState.chessboardBuild.set((c) => {
		c.delegate = {
			completedMoveAnimation: noop,
			onPositionUpdated: () => {
				set(([_s]) => {});
			},

			madeManualMove: () => {
				get(([_s, _rs]) => {});
			},
			onBack: () => {
				set(([_s]) => {});
			},
			onReset: () => {
				set(([_s]) => {});
			},
			onMovePlayed: () => {
				set(([_s, _rs]) => {});
			},
			shouldMakeMove: (_move: Move) =>
				set(([_s]) => {
					return true;
				}),
		};
	});

	return initialState;
};
